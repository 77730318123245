let musicRef = null;

const PlayMusic = (audioUrl) => {
  if (!musicRef) {
    musicRef = new Audio(audioUrl);
    musicRef.loop = false;
  } else {
    musicRef.src = audioUrl;
  }
  musicRef.play().catch((err) => {});
  return musicRef;
};

export default PlayMusic;
