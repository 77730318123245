import axios from "axios";

export let headers = {
  "Tenant-Access-Token": "OGQ3MzEzZjM0NTcxNGU5MWFiYjEwN2UzMWEyMGE2OGQ=",
  "Tenant-Access-Key": "6JoBS00lRJ9N6g",
};

export const api = axios.create({
  baseURL: "https://tiltlabs-dev.confirmu.com",
  headers,
});

export const BASE_URL = "https://tiltlabs-dev.confirmu.com";
