const fullScreen = () => {
  const getFullScreen = () => {
    return (
      document.fullscreenElement ||
      document.webkitFullscreenEleent ||
      document.mozFullscreenElement ||
      document.msFullscreenElement
    );
  };

  const toggle = () => {
    if (getFullScreen()) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen().catch((e) => {});
    }
  };

  return toggle;
};

export default fullScreen;
