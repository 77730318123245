import {
  FETCH_SHUFFLE_SUCCESS,
  SAVE_SHUFFLE_AUDIO,
  SAVE_IMAGE_DATA_SET,
  SAVE_LEVELS,
  SAVE_NARRATION_DATA_SET,
  SAVE_SCENE_DATA_LIST,
  SAVE_GAME_SESSION_ID,
  SAVE_SCENE_ID,
} from '../types';

export const saveShuffleData = (data) => {
  return {
    type: FETCH_SHUFFLE_SUCCESS,
    payload: data,
  };
};

export const saveSuffleAudio = (audio) => {
  return {
    type: SAVE_SHUFFLE_AUDIO,
    payload: audio,
  };
};

export const saveImageDataSet = (image) => {
  return {
    type: SAVE_IMAGE_DATA_SET,
    payload: image,
  };
};

export const saveLevels = (levels) => {
  return {
    type: SAVE_LEVELS,
    payload: levels,
  };
};

export const saveNarrationDataSet = (data) => {
  return {
    type: SAVE_NARRATION_DATA_SET,
    payload: data,
  };
};

export const saveSceneDataList = (scenesData) => {
  return {
    type: SAVE_SCENE_DATA_LIST,
    payload: scenesData,
  };
};

export const saveGameSessionId = (id) => {
  return {
    type: SAVE_GAME_SESSION_ID,
    payload: id,
  };
};

export const saveSceneId = (sceneId) => {
  return {
    type: SAVE_SCENE_ID,
    payload: sceneId,
  };
};
