import React from 'react';
import log from '../../utils/log';
import { Alert } from '@material-ui/lab';
import './style.scss';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    log(error, errorInfo);
  }

  handleClick = () => {
    return window.location.replace(window.location.href);
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className="alert__container">
          <Alert
            className="error_red"
            onClick={this.handleClick}
            severity="error"
          >
            Something went wrong.Click here to retry
          </Alert>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
