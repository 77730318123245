import {
  INTIAl_LOADING,
  CURRENT_SCREEN_ID,
  SET_INDEX,
  START_STOP_WATCH,
  STOP_STOP_WATCH,
  AVATAR_GENDER,
  TOGGLE_MUSIC,
  TOGGLE_VOICE_OVER,
  TIP_QUESTION,
  IS_WINDOW_OPEN,
} from '../types';
const intialState = {
  screenID: '',
  currentIndex: 0,
  startStopWatch: false,
  gender: '',
  playMusic: false,
  playVoiceOver: false,
  isWindowOpen: false,
};

const gameTransistionReducer = (state = intialState, action) => {
  switch (action.type) {
    case INTIAl_LOADING:
      return { ...state, screenID: action.payload };
    case CURRENT_SCREEN_ID:
      return { ...state, screenID: action.payload };
    case SET_INDEX:
      return { ...state, currentIndex: action.payload };
    case START_STOP_WATCH:
      return { ...state, startStopWatch: action.payload };
    case STOP_STOP_WATCH:
      return { ...state, startStopWatch: action.payload };
    case AVATAR_GENDER:
      return { ...state, gender: action.payload };
    case TOGGLE_MUSIC:
      return {
        ...state,
        playMusic: action.payload,
      };
    case TOGGLE_VOICE_OVER:
      return {
        ...state,
        playVoiceOver: action.payload,
      };
    case TIP_QUESTION:
      return {
        ...state,
        tipQuestion: action.payload,
      };
    case IS_WINDOW_OPEN:
      return {
        ...state,
        isWindowOpen: action.payload,
      };
    default:
      return state;
  }
};

export default gameTransistionReducer;
