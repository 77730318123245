import {
  SAVE_SHUFFLE_AUDIO,
  SAVE_IMAGE_DATA_SET,
  SAVE_LEVELS,
  SAVE_NARRATION_DATA_SET,
  SAVE_SCENE_DATA_LIST,
  SAVE_GAME_SESSION_ID,
  SAVE_SCENE_ID,
} from '../types';
const intialState = {
  audioBaseUrl: {},
  imageDataSet: {},
  levels: [],
  narrationDataSet: {},
  sceneDataList: [],
  gameSessionId: '',
  sceneId: [],
};

const shuffleReducer = (state = intialState, action) => {
  switch (action.type) {
    case SAVE_SHUFFLE_AUDIO:
      return { ...state, audioBaseUrl: action.payload };
    case SAVE_IMAGE_DATA_SET:
      return { ...state, imageDataSet: action.payload };
    case SAVE_LEVELS:
      return { ...state, levels: [...action.payload] };
    case SAVE_NARRATION_DATA_SET:
      return { ...state, narrationDataSet: action.payload };
    case SAVE_SCENE_DATA_LIST:
      return { ...state, sceneDataList: [...action.payload] };
    case SAVE_GAME_SESSION_ID:
      return { ...state, gameSessionId: action.payload };
    case SAVE_SCENE_ID:
      return { ...state, sceneId: [...action.payload] };
    default:
      return state;
  }
};

export default shuffleReducer;
